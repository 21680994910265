<template>
  <div>
    <b-row class="match-height">
      <b-col sm="3">
        <b-card class="earnings-card">
          <b-card-title class="mb-1 text-center">
            T.R.M Del Día
          </b-card-title>
          <b-row class="match-height text-center mt-4">
            <b-col sm="6">
              <div>
                <p class="dollar">$ {{ trmDay }}</p>
              </div>
            </b-col>
            <b-col sm="6">
              <div class="font-small-2">
                <feather-icon icon="TrendingUpIcon" size="30" class="primary" />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col sm="2">
        <b-card class="earnings-card">
          <b-card-title class="mb-1 text-center">
            Váucher en Emitidos
          </b-card-title>
          <b-row class="match-height text-center mt-4">
            <b-col sm="6">
              <div>
                <p class="dollar">{{ issued }}</p>
              </div>
            </b-col>
            <b-col sm="6">
              <div class="font-small-2">
                <feather-icon icon="AlertTriangleIcon" size="30" class="primary" />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col sm="2">
        <b-card class="earnings-card">
          <b-card-title class="mb-1 text-center">
            Váucher en StandBy
          </b-card-title>
          <b-row class="match-height text-center mt-4">
            <b-col sm="6">
              <div>
                <p class="dollar">{{ standBy }}</p>
              </div>
            </b-col>
            <b-col sm="6">
              <div class="font-small-2">
                <feather-icon icon="AlertTriangleIcon" size="30" class="primary" />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col sm="2">
        <b-card class="earnings-card">
          <b-card-title class="mb-1 text-center">
            Váucher Activos
          </b-card-title>
          <b-row class="match-height text-center mt-4">
            <b-col sm="6">
              <div>
                <p class="dollar">{{ current }}</p>
              </div>
            </b-col>
            <b-col sm="6">
              <div class="font-small-2">
                <feather-icon icon="DollarSignIcon" size="30" class="primary" />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col sm="2">
        <b-card class="earnings-card">
          <b-card-title class="mb-1 text-center">
            Váucher Por Finalizar
          </b-card-title>
          <b-row class="match-height text-center mt-4">
            <b-col sm="6">
              <div>
                <p class="dollar">{{ vouchers }}</p>
              </div>
            </b-col>
            <b-col sm="6">
              <div class="font-small-2">
                <feather-icon icon="ClockIcon" size="30" class="primary" />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col sm="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Destinos Clientes Top 10</b-card-title>
          </b-card-header>
          <!-- chart -->
          <b-card-body>
            <chartjs-component-bar-chart :height="400" :data="latestBarChart.data" :options="latestBarChart.options"
              :key="graphic" />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Planes Más Vendidos</b-card-title>
          </b-card-header>

          <!-- chart -->
          <b-card-body>
            <chartjs-component-doughnut-chart :height="275" :data="doughnutChart.data" :options="doughnutChart.options"
              class="mb-3" :key="graphic" />
              <b-row>
                <b-col sm="6" v-for="(item, index) in doughnutChart.data.datasets[0].labels" :key="index" class="mb-2">
                  <div class="align-items-center">
                    <feather-icon icon="TrendingUpIcon" size="16" class="primary" />
                    <span class="font-weight-bold ml-75 mr-25">{{ item }}</span>
                    <span>- {{ doughnutChart.data.datasets[0].data[index] }}</span>
                  </div>
                </b-col>
              </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col sm="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Ventas En Los Últimos 6 Meses</b-card-title>
          </b-card-header>
          <!-- chart -->
          <b-card-body>
            <vue-apex-charts type="line" height="400" :options="lineChartSimple.chartOptions"
              :series="lineChartSimple.series" :key="graphic" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { $themeColors } from '@themeConfig'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'
import ChartjsComponentDoughnutChart from './ChartjsComponentDoughnutChart.vue'
import VueApexCharts from 'vue-apexcharts'

import { BCard, BCardText, BCardTitle, BLink, BRow, BCol, BCardHeader, BCardBody } from 'bootstrap-vue'
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
const tenColors = [
  '#4CAF50', // Verde
  '#FFC107', // Amarillo
  '#2196F3', // Azul
  '#FF5722', // Naranja
  '#9C27B0', // Morado
  '#607D8B', // Gris
  '#E91E63', // Rosa
  '#FF9800', // Naranja claro
  '#00BCD4', // Cyan
  '#F44336'  // Rojo
];
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BCardTitle,
    BCardHeader,
    BCardBody,
    ChartjsComponentBarChart,
    ChartjsComponentDoughnutChart,
    VueApexCharts
  },
  data() {
    return {
      graphic: 1,
      vouchers: null,
      stockData: [
        {
          device: 'Desktop', symbol: 'MonitorIcon', color: 'text-primary', percentage: 80, upDown: 2,
        },
        {
          device: 'Mobile', symbol: 'TabletIcon', color: 'text-warning', percentage: 10, upDown: 8,
        },
        {
          device: 'Tablet', symbol: 'TabletIcon', color: 'text-success', percentage: 10, upDown: -5,
        },
      ],
      latestBarChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 10,
                  min: 0,
                  // max: 100,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
      doughnutChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: true },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ''
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} %`
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: [],
              data: [],
              backgroundColor: tenColors,
              borderWidth: 0,
              pointStyle: 'rectRounded',
            },
          ],
        },
      },
      lineChartSimple: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50">$ <span>'}${data.series[data.seriesIndex][data.dataPointIndex]
                }</span></div>`
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
      trmDay: '',
      issued: '',
      standBy: '',
      current: '',
      showGrafica: false,
      finish: null,
    }
  },
  async created() {
    this.trm();
    this.voucherIssued();
    this.voucherStandby();
    this.voucherCurrent();
    this.clientTopTeen();
    this.voucherFinish();
    await this.charTopTeen();
    this.graphic++
  },
  methods: {
    async charTopTeen() {
      await this.$http.get('/charts/grafica-top-10').then((response) => {
        this.latestBarChart.data.labels = [...response.data.countries]
        this.latestBarChart.data.datasets[0].data = [...response.data.count]


        this.doughnutChart.data.datasets[0].labels = [...response.data.dataPlansLabels]
        this.doughnutChart.data.datasets[0].data = [...response.data.dataPlansCounts]

        this.lineChartSimple.series[0].data = [...response.data.salesSum]
        this.lineChartSimple.chartOptions.xaxis.categories = [...response.data.salesLabels]

      })
    },
    trm() {
      this.$http.get('/dashboard/consulta-trm').then((response) => {
        this.trmDay = response.data.trm
      })
    },
    voucherIssued() {
      this.$http.get('/dashboard/vouchers-issued').then((response) => {
        this.issued = response.data.voucherIssued
      })
    },
    voucherStandby() {
      this.$http.get('/dashboard/vouchers-standby').then((response) => {
        this.standBy = response.data.voucherStandBy
      })
    },
    voucherCurrent() {
      this.$http.get('/dashboard/vouchers-current').then((response) => {
        this.current = response.data.voucherCurrent
      })
    },
    voucherFinish() {
      this.$http.get('/dashboard/vouchers-finish').then((response) => {
        this.vouchers = response.data.vouchers
      })
    },
    clientTopTeen() {
      this.$http.get('/dashboard/top-teen-client').then((response) => {
        response.data.countVoucher.forEach(element => {
          this.barChart?.series[0]?.data.push(element.cantidad)
          this.barChart?.chartOptions?.xaxis?.categories.push(element.pais)
        });
        this.showGrafica = true;
      })
    }
  }
}
</script>

<style>
.dollar {
  font-size: 20px;
  color: orange;
}
</style>
